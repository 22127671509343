<template>
  <MainLayout
    isFullScreen
    class="main-layout--artist-edit-agenda">
    <section class="artist-profile-edit-agenda__wrapper">
      <header>
        <Heading
          :tag="'h1'"
          :level="'h3'"
          class="mb-sm">
          {{ $t('artist.dashboard.menu.editAgenda.updateAgenda', { firstName: user.Firstname }) }}
        </Heading>
        <Paragraph class="mb-xl">
          {{ $t('artist.dashboard.menu.editAgenda.infoAgenda') }}
        </Paragraph>
      </header>

      <ArtistAgenda></ArtistAgenda>
    </section>

    <img
      src="../../../../public/assets/images/artiste/editagenda.jpg"
      alt=""
      class="artist-profile-edit-agenda__hero">
  </MainLayout>
</template>

<script>

import {
  mapState,
  mapActions,
}                         from 'vuex';

import MainLayout         from '../../../components/layout/MainLayout/l-MainLayout.vue';
import Heading            from '../../../components/atoms/Heading/a-Heading.vue';
import Paragraph          from '../../../components/atoms/Paragraph/a-Paragraph.vue';
import ArtistAgenda       from '../../ArtistAgenda/components/o-ArtistAgenda.vue';


export default {
  name: 'o-ArtistEditAgenda',
  components: {
    MainLayout,
    Heading,
    Paragraph,
    ArtistAgenda,
  },
  async created() {
    await this.fetchMe();
  },
  computed: {
    ...mapState('User', ['user']),
  },
  methods: {
    ...mapActions({ fetchMe: 'User/FETCH_ME' }),
  },
}

</script>

<style lang="scss">

.artist-profile-edit-agenda {
  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: var(--space-xl) var(--space-base);
    background-color: var(--color-grey-background);
    border-radius: var(--rounded-xl);

    @media screen and ($desktop) {
      padding: var(--space-xl) var(--space-xxl);
    }
  }

  &__hero {
    min-height: 100%;
    max-height: 100%;
    min-width: 100%;
    max-width: 100%;
    object-fit: cover;
    grid-row: 1 / 2;

    @media screen and ($desktop) {
      grid-row: 1 / 2;
      grid-column: 2 / 3;
    }
  }
}

</style>
