<template>
  <AgendaCalendar
    v-if="isPublic"
    v-bind="$attrs"
    @availability-selected="handleAvailabilitySelected"
    @month-changed="handleFetchArtistPublicAvailabilities"
    :availabilities="publicAvailabilities"
    :isLoading="isAvailabilitiesLoading"
    :startDate="selectedAvailabilityStartDate"
    isPublic
    isShowLegend
    isGreyHeader>
  </AgendaCalendar>
  <AgendaCalendar
    v-else
    v-bind="$attrs"
    @month-changed="fetchAvailabilities"
    @availabilities-changed="editAvailabilities"
    :availabilities="availabilities"
    :isLoading="isAvailabilitiesLoading"
    isShowHeader
    isShowTags>
  </AgendaCalendar>
</template>

<script>

import {
  mapState,
  mapGetters,
  mapMutations,
  mapActions
}                             from 'vuex';

import AgendaCalendar         from '../../../components/molecules/AgendaCalendar/m-AgendaCalendar.vue';


export default {
  name: 'o-ArtistAgenda',
  components: {
    AgendaCalendar,
  },
  props: {
    isPublic: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('Availabilities', ['isAvailabilitiesLoading']),
    ...mapState('SearchArtist', ['currentArtist', 'selectedAvailability']),
    ...mapGetters({
      publicAvailabilities: 'SearchArtist/CALENDAR_AVAILABILITIES',
      availabilities: 'Availabilities/CALENDAR_AVAILABILITIES',
    }),
    selectedAvailabilityStartDate() {
      if (!this.selectedAvailability.timestamp) return;

      const date = new Date(this.selectedAvailability.timestamp);
      const startDate = {
        month: date.getMonth() + 1,
        year: date.getFullYear(),
      };

      return startDate;
    },
  },
  methods: {
    ...mapMutations({
      setCurrentAvailability: 'SearchArtist/SET_CURRENT_AVAILABILITY',
    }),
    ...mapActions({
      fetchAvailabilities: 'Availabilities/FETCH_AVAILABILITIES',
      editAvailabilities: 'Availabilities/EDIT_AVAILABILITIES',
      fetchArtistPublicAvailabilities: 'SearchArtist/FETCH_ARTIST_PUBLIC_AVAILABILITIES',
    }),
    async handleFetchArtistPublicAvailabilities(date) {
      const params = {
        input: {
          artistID: this.currentArtist.ID,
          date,
        },
      };

      await this.fetchArtistPublicAvailabilities(params);
    },
    handleAvailabilitySelected(selectedAvailability) {
      const shouldDeselectAvailability = this.selectedAvailability?.EventDate === selectedAvailability?.EventDate;

      this.setCurrentAvailability(shouldDeselectAvailability ? {} : selectedAvailability);
    }
  }
}

</script>
